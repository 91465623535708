import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'

const OpeningModal = () => {
    const [showModal, setShowModal] = useState(true)
  return (
    <div>
  <Modal className="custom-modal" show={showModal} onClick={() => setShowModal(false)}>
  <Modal.Header>
    <Modal.Title className='animate__bounceInLeft'>Welcome to the HEATER!</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className='text-center'>
      

    <p className='animate__animated animate__zoomIn'>
  HEATER is a daily quiz app where you can test your knowledge on a broad range of topics.
</p>
<p className='animate__animated animate__zoomIn'>
  There will be five new questions every day for each category.
</p>
<p className='animate__animated animate__zoomIn'>
  Make sure you get your answer in before the timer expires, or your quiz will be over!
</p>


</div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="light" onClick={() => setShowModal(true)}>
      Start
    </Button>
  </Modal.Footer>
</Modal>
    </div>
  )
}

export default OpeningModal
