export async function LogOut(token) {
    try {
        // Make an API request to fetch user information
        const response = await fetch('https://heaterbackend.darien-c.com/quiz/logout/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });
        if (response.ok) {
          
          
          
          localStorage.removeItem('authToken');
          window.location.reload();
        
          
        } else {
          // Handle error if user information retrieval fails
          throw new Error('Failed to fetch user information');
        }
      } catch (error) {
        console.log(error);
      }
  }
  