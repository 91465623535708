// api.js

export const AnonPostScore = async (quizId, score) => {
    const url = 'https://heaterbackend.darien-c.com/quiz/postAnonScore/';
  
    const requestOptions = {
      method: 'POST',
      credentials: 'include', // Include cookies
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz_id: quizId,
        score: score,
      }),
    };
  
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error('Error updating score');
      }
    } catch (error) {
      console.error('Error updating score:', error);
      throw error;
    }
  };
  
