async function DeleteAccount(token) {
  try {
    const response = await fetch('https://heaterbackend.darien-c.com/quiz/delete-account/', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
    });

    if (response.ok) {
      // Account deletion successful, remove the authentication token from local storage
      localStorage.removeItem('authToken');
      window.location.reload(); // Reload the page after successful deletion
    } else {
      // Handle error if account deletion fails
      throw new Error('Failed to delete account');
    }
  } catch (error) {
    // Handle network or other errors
    console.error('Error deleting account:', error);
    throw error; // Re-throw the error to handle it in the calling code if necessary
  }
}

export default DeleteAccount;
