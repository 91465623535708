import React from 'react';
import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './LoginModal.css';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleLoginFunc } from '../services/GoogleLoginFunc';
import fetchPercentileData from '../services/GetPercentiles'
import { LogOut } from '../services/LogOut';
import DeleteAccount from '../services/DeleteAccount';
import FacebookLogin from './FacebookLogin';

const LoginModal = ({ show, close, userName, email, token, score }) => {
  const authToken = localStorage.getItem('authToken')
  const [loggedIn, setLoggedIn] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // State for the confirmation modal

  const [activeTab, setActiveTab] = useState('user-info');
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleDeleteAccount = () => {
    setShowConfirmationModal(true);
  };
  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
  };
  const handleConfirmDelete = () => {
    DeleteAccount(authToken)
  .then(() => {
    // This code will execute if the account deletion is successful
    console.log('Account successfully deleted.');
  })
  .catch((error) => {
    // This code will execute if the account deletion fails
    console.error('Failed to delete account:', error);
  });

  };
  useEffect(()=>{
    console.log(score)
  },[])
  const handlePercentile =() =>{
    fetchPercentileData(authToken)
  }

    if(!userName){
      return(
    <div>
      
      
      <Modal show={show} onHide={close}className="login-modal">
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>

      <GoogleLogin onSuccess={GoogleLoginFunc} className='googleLogin'/>
      <br/>
     

        </Modal.Body>
        <Modal.Footer>
       
        </Modal.Footer>
      </Modal>
    </div>
  
)}
return(
  <div>
    
  <Modal show={show} onHide={close} className="user-modal text-center">
  <Modal.Header>
    
    
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'user-info' ? 'active' : ''}`}
          onClick={() => handleTabChange('user-info')}
        >
          User Information
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'percentile-rankings' ? 'active' : ''}`}
          onClick={() => handleTabChange('percentile-rankings')}
        >
          Percentile Rankings
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'account-management' ? 'active' : ''}`}
          onClick={() => handleTabChange('account-management')}
        >
          Account Management
        </button>
      </li>
    </ul>
  </Modal.Header>
  <Modal.Body>
    {activeTab === 'user-info' && (
      <div>
        <h5>User Information</h5>
        <br/>
        <p>Username: {userName}</p>
        <p>Email: {email}</p>
      </div>
    )}
    {activeTab === 'percentile-rankings' && (
      <div>
        <h5>Percentile Rankings</h5>
        <br/>
        <h7>Coming Soon....</h7>
      </div>
    )}
    {activeTab === 'account-management' && (
      <div>
        <h5>Account Management</h5>
        <br/>
        <Button variant='light' onClick={() => setShowTerms(!showTerms)}>
          Terms of Service{' '} (click to {showTerms ? 'hide' : 'show'})

        </Button>
        <br/>
        {showTerms && (
          <div>
            {/* Your terms of service content goes here */}
            <div>
            <h1>Heater Trivia App - Terms of Service</h1>

<h2>1. Use of the App</h2>
<p>
  1.1 Eligibility: Heater is intended for users who are at least 13 years old. If you are under 13, you must not use the app. By using Heater, you affirm that you are 13 years of age or older.
</p>
<p>
  1.2 License: Heater grants you a non-exclusive, non-transferable, revocable license to use the app for personal, non-commercial purposes. You must not use the app for any illegal, unauthorized, or prohibited purpose.
</p>

<h2>2. User Content</h2>
<p>
  2.1 User Submissions: Heater allows users to submit trivia questions, comments, and other content ("User Content"). By submitting User Content, you grant Heater a worldwide, royalty-free, perpetual, irrevocable, sub-licensable, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, distribute, publicly display, and perform the User Content in any medium.
</p>
<p>
  2.2 Responsibility: You are solely responsible for your User Content. Heater does not endorse, guarantee, or vouch for the accuracy, completeness, or quality of User Content. You must not submit any content that is unlawful, harmful, offensive, or violates the rights of others.
</p>

<h2>3. Intellectual Property</h2>
<p>
  3.1 Ownership: All intellectual property rights in Heater, including but not limited to trademarks, logos, text, graphics, and software, are owned by Heater or its licensors. Your use of Heater does not grant you any ownership or rights to the intellectual property.
</p>
<p>
  3.2 Copyright Complaints: Heater respects the intellectual property rights of others. If you believe that your copyrighted work has been used in a way that constitutes copyright infringement, please contact us through the app.
</p>

<h2>4. Disclaimer of Warranties</h2>
<p>
  4.1 Heater provides the app on an "as-is" and "as-available" basis. We do not make any warranties or representations, express or implied, regarding the accuracy, reliability, or suitability of the app.
</p>
<p>
  4.2 We do not guarantee that the app will be error-free, uninterrupted, secure, or free from viruses or other harmful components. Your use of the app is at your own risk.
</p>

<h2>5. Limitation of Liability</h2>
<p>
  5.1 To the extent permitted by law, Heater and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or in connection with your use of the app.
</p>
<p>
  5.2 In no event shall Heater's total liability exceed the amount you paid, if any, for accessing the app.
</p>

<h2>6. Modifications and Termination</h2>
<p>
  6.1 Heater may update or modify these Terms from time to time. We will notify you of significant changes. Your continued use of the app after the changes will constitute acceptance of the revised Terms.
</p>
<p>
  6.2 Heater reserves the right to terminate, suspend, or discontinue the app or your access to the app at any time, with or without notice, for any reason or no reason.
</p>

<h2>7. Governing Law and Jurisdiction</h2>
<p>
  7.1 These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of laws principles.
</p>
<p>
  7.2 Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Your Country/State].
</p>
</div>
          </div>
        )}
        <br/>
        <Button variant='light' onClick={() => setShowPrivacyPolicy(!showPrivacyPolicy)}>
          Privacy Policy{' '} (click to {showPrivacyPolicy ? 'hide' : 'show'})

        </Button>
        <br/>
        {showPrivacyPolicy && (

          <div>
                <h1>Privacy Policy for Heater Trivia App</h1>
    <p>This Privacy Policy outlines how Heater ("we," "us," or "our") collects, uses, discloses, and protects the personal information of users ("you" or "your") of the Heater trivia app ("App"). By using the App, you consent to the practices described in this policy.</p>

    <h2>1. Information We Collect</h2>
    <p>
        <strong>1.1. Personal Information:</strong> When you use the App, we may collect certain personal information, such as your name, email address, and other information you provide during the registration process or while using the App.
    </p>
    <p>
        <strong>1.2. Trivia Activity Data:</strong> We may collect data related to your trivia activity, including answers to trivia questions, scores, and other gameplay information.
    </p>
    <p>
        <strong>1.3. Device Information:</strong> We may collect information about the device you use to access the App, such as the device type, operating system, and unique device identifiers.
    </p>
    <p>
        <strong>1.4. Log Data:</strong> Like many other apps, we may automatically collect certain information whenever you interact with the App. This information may include your IP address, browser type, the pages of the App you visit, the time and date of your visit, and other statistics.
    </p>

    <h2>2. How We Use Your Information</h2>
    <p>
        <strong>2.1. Provide and Improve the App:</strong> We use the information collected to operate and maintain the App, provide you with its features and functionalities, and continually improve your experience.
    </p>
    <p>
        <strong>2.2. Communication:</strong> We may use your email address to send you updates, announcements, and promotional materials related to the App. You can opt-out of receiving such communications at any time by following the instructions in the email.
    </p>
    <p>
        <strong>2.3. Analytics:</strong> We may use aggregated and anonymized information for analytical purposes to understand how users interact with the App and to identify areas for improvement.
    </p>

    <h2>3. Sharing of Your Information</h2>
    <p>
        <strong>3.1. Third-Party Service Providers:</strong> We may engage third-party service providers to assist us in operating the App, performing services on our behalf, or analyzing user behavior. These service providers will have access to your information only to the extent necessary to perform their functions and are obligated to maintain the confidentiality of your data.
    </p>
    <p>
        <strong>3.2. Legal Compliance:</strong> We may disclose your information if required by law, to protect our rights or the rights of others, or to comply with a court order, subpoena, or similar legal process.
    </p>

    <h2>4. Data Security</h2>
    <p>
        We take data security seriously and employ reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please understand that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
    </p>

    <h2>5. Children's Privacy</h2>
    <p>
        The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will promptly delete such information from our records.
    </p>

    <h2>6. Changes to this Privacy Policy</h2>
    <p>
        We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated policy on the App. By continuing to use the App after any modifications, you agree to be bound by the updated Privacy Policy.
    </p>

    <h2>7. Contact Us</h2>
    <p>
        If you have any questions, concerns, or requests regarding this Privacy Policy or the App's privacy practices, please contact us at [Your contact email].
    </p>
          </div>
        )}
        <br/>
        <p>
          <Button variant="danger" onClick={handleDeleteAccount}>Delete Account</Button>
        </p>
      </div>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant='dark' onClick={() => LogOut(authToken)}>Logout</Button>
  </Modal.Footer>
</Modal>
 <Modal show={showConfirmationModal} onHide={handleCancelDelete} className="confirmation-modal text-center">
 <Modal.Header>
   <Modal.Title>Confirm Account Closure</Modal.Title>
 </Modal.Header>
 <Modal.Body>
   <p>All user data and progress will be deleted.</p>
   <p>Click "Confirm" to proceed with account closure.</p>
 </Modal.Body>
 <Modal.Footer>
   <Button variant='secondary' onClick={handleCancelDelete}>Cancel</Button>
   <Button variant='danger' onClick={handleConfirmDelete}>Confirm</Button>
 </Modal.Footer>
</Modal>
</div>
  );
}

export default LoginModal;
