import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<GoogleOAuthProvider clientId="453249256809-0gudgi4vts9vi9br2kic65rqset48rvf.apps.googleusercontent.com">
    <App />
    </GoogleOAuthProvider>
);

reportWebVitals();
