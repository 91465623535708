import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Card } from 'react-bootstrap';
import QuizPage from './QuizPage';
import LoginModal from './LoginModal';
import { QuizFetch } from '../services/QuizFetch';

import './HomeModal.css';

const HomeModal = ({ quizData, userQuizScores, email }) => {
  const [show, setShow] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [quizDataFinal, setQuizDataFinal] = useState([]);
  const [passQuizID, setPassQuizID] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [quizScoreArray, setQuizScoreArray] = useState(null);
  const [showLogin ,setShowLogin] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOverlayClick = (quizID) => {
    setShow(false);
    setShowQuiz(true);
    setPassQuizID(quizID);
  };
  const handleLoginShow = () => {
    setShow(false);
    setShowLogin(true);
  }

  const handleHideOverlay = (quizID) => {
    setShowQuiz(false);
  };

  useEffect(() => {
    async function fetchData() {
      const data = await QuizFetch();
      const updatedQuizData = [];
      for(let i =0; i < data.length; i++){
        if (data[i]['todays_challenge'] === true) {
          console.log(data[i].todays_challenge)
          updatedQuizData.push(data[i]);
        }

      }
      setQuizDataFinal(updatedQuizData)
      
    }

    fetchData();

    
  }, []);
  useEffect(()=>{
    const checkLogin = () => {
      
      if (userQuizScores) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };

    checkLogin();
  },[userQuizScores])

  useEffect(() => {
    setUserEmail(email);

  }, [email]);

  useEffect(() => {
    setQuizScoreArray(userQuizScores);
  }, [userQuizScores]);

  const CheckScores = (index) => {
    if(loggedIn === false){
      return <p>Please Login To Start Quiz</p>
    }
    else if (!quizScoreArray) {
      return <p>Loading...</p>;
    }
  
    if (quizScoreArray.length === 0) {
      return <p>Click To Begin Today's Quiz</p>;
    }
  
    const score = quizScoreArray.find((item) => item.quizType === (index + 1));
    if (score) {
      const formattedScore = `${score.score} / 5`;
      return (
        <div className="score-container">
          <p className="score">{formattedScore}</p>
          <div className="score-bar">
            <div className="score-fill" style={{ width: `${(score.score / 5) * 100}%` }}></div>
          </div>
        </div>
      );
    } else {
      return <p>Click To Begin Today's Quiz</p>;
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="home_modal">
        <Modal.Header>
          <h6>Today's Challenges</h6>
        </Modal.Header>
        <Modal.Body>
          {quizDataFinal &&
            quizDataFinal.map((quiz, index) => (
              <div key={quiz.id}>
                <Card
                  className="custom-card text-center"
                  onClick={() => handleOverlayClick(quiz.id)}
                  >
                  <Card.Body>
                    <Card.Title className="card-title">{quiz.title}</Card.Title>
                    {loggedIn ? (
                      <div className="quiz-details">
                        {CheckScores(index)}
                      </div>
                    ) : (
                      <div className="quiz-details">
                        <p>Click To Play</p>
                      </div>
                    )}
                  </Card.Body>
                </Card>
                <br />
              </div>
            ))}
        </Modal.Body>
      </Modal>
      {showQuiz && <QuizPage quizData={quizDataFinal} quizID={passQuizID} email={email} loginStatus = {loggedIn}/>}
      <LoginModal show={showLogin} close={() => setShowLogin(false)} />
    </div>
  );
};

export default HomeModal;
