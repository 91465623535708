import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AnimatePresence } from 'framer-motion';
import 'animate.css';
import ParticleBackground from './components/ParticleBackground';
function App() {
  return (
<BrowserRouter>
      <div className="App" style={{ position: 'relative', height: '100vh', width: '100%' }}>
        {/* <Header /> */}
        {/* <ToastContainer /> */}
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -100 }}>
        <ParticleBackground />
      </div>
        <AnimatePresence mode='wait'>

        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
        </AnimatePresence>

      </div>
    </BrowserRouter>
  );
}

export default App;
