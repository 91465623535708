export async function UserData(authToken) {
    try {
        // Make an API request to fetch user information
        const response = await fetch('https://heaterbackend.darien-c.com/quiz/userData/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const { username, email } = data; // Extract username and email from the data
          return { username, email }; // Return an object with username and email properties

          // setUserModal(true);
        } else {
          // Handle error if user information retrieval fails
          throw new Error('Failed to fetch user information');
        }
      } catch (error) {
        console.log(error);
      }}

