import React, { useState, useEffect } from 'react';
import { Modal, Card, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import LoginModal from './LoginModal';
import './ShowResults.css';

const ShowResults = ({ resultsArray, show, onHide, scoreSet, score, reloadHide, pScore}) => {
  const { title, questions, id } = resultsArray;
  const [showResults, setShowResults] = useState(true);
  const [answerSet, setAnswerSet] = useState('');
  const [hoveredAnswer, setHoveredAnswer] = useState('');
  const [quizScore, setQuizScore] = useState('')
  const [showLogin, setShowLogin] = useState(false)
  const [passScore, setPassScore] = useState('')
  const [loggedIn, setLoggedIn] = useState(null)


  const handleHide = () => {
    setShowResults(false);
    if(reloadHide){
      window.location.reload()
    }

    onHide(); // Call the onHide callback from the parent component
  };
  const handleLoginShow = () =>{
    setShowResults(false)
    setShowLogin(true)
  }


  const getAnswer = (question, index) => {
    setAnswerSet(question.answers);
    for (let i = 0; i < answerSet.length; i++) {
      if (answerSet[i]['is_correct'] === true) {
        setHoveredAnswer(answerSet[i]['text']);
      }
    }
  };
  useEffect(()=>{
    if(scoreSet){
      console.log('this')
      setQuizScore(scoreSet)
    }else{
      console.log("resultsarray ", resultsArray)
      let id = resultsArray.id
      console.log(score)

    setQuizScore(score[id])

    }


  },[])
  const CheckScores = (score) => {
    

 
    localStorage.setItem('quizID', id)
    localStorage.setItem('anonScore', score)

    
    
    
    if (score === 19) {
      const formattedScore ='0 / 5'  ;
     
      return (
        <div>
          <div><h6>Today's Score</h6></div>
        
        <div className="score-container animate__animated animate__zoomIn">
          
          <p className="score">{formattedScore}</p>
        
          <div className="score-bar">
            <div className="score-fill" style={{ width: `${(0 / 5) * 100}%` }}></div>

          </div>
          </div>
        </div>
      );


    } else if(score){
      const formattedScore = `${score} / 5`;
      
     
      return (
        <div>
          <div><h6>Today's Score</h6></div>
        
        <div className="score-container animate__animated animate__zoomIn">
          
          <p className="score">{formattedScore}</p>
         
          <div className="score-bar">
            <div className="score-fill" style={{ width: `${(score / 5) * 100}%` }}></div>

          </div>
          </div>
        </div>
      );
    }
    else if(pScore?.quizType === resultsArray?.id){
      const formattedScore = `${pScore['score']} / 5`;
      console.log('yeo')
      
     
      return (
        <div>
          <div><h6>Today's Score</h6></div>
        
        <div className="score-container animate__animated animate__zoomIn">
          
          <p className="score">{formattedScore}</p>
         
          <div className="score-bar">
            <div className="score-fill" style={{ width: `${(pScore['score'] / 5) * 100}%` }}></div>

          </div>
          </div>
        </div>
      );
    }
    else{
      const formattedScore ='0 / 5'  ;
     
      return (
        <div>
          <div><h6>Today's Score</h6></div>
        
        <div className="score-container animate__animated animate__zoomIn">
          
          <p className="score">{formattedScore}</p>
          <div className="score-bar">
            <div className="score-fill" style={{ width: `${(0 / 5) * 100}%` }}></div>

          </div>
          </div>
        </div>
      );


    }
  };
  useEffect(()=>{
    setPassScore(score)
  },[])
  useEffect(()=>{
    console.log('score :', score)

    if(localStorage.getItem('authToken')){
      setLoggedIn(true)
    }
  },[])
  
  
  return (
    <div>
      <div>
      
    <Modal show={showResults} onHide={handleHide} className="show-results-modal">
      <Modal.Header>
        <Modal.Title className="show-results-title">{title}
        <div>{CheckScores(quizScore)}</div>
        
        {!loggedIn && (
          <div>
          
  <Button variant='info' onClick={handleLoginShow}>Login To Save Score</Button>
  </div>
)}




       
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          {questions.map((question, index) => (
            <div
              key={index}
              
            >
              <Accordion.Item
                eventKey={index}
                className="question-text"
                onClick={() => getAnswer(question, index)}
              >
                <Accordion.Header className='question-item'>{question.text}</Accordion.Header>
                <Accordion.Body>
                  {question.answers.map((answer, answerIndex) => (
                    <div
                      key={answerIndex}
                      className={`answer ${answer.is_correct ? 'correct-answer' : ''}`}
                    >
                      {answer.text}
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </div>
          ))}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
    </div>

   
      <LoginModal score={passScore} show={showLogin} onHide={onHide}/> 

    
    
    
    </div>
  );
};

export default ShowResults;
