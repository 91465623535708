import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Fader from '../services/Fader';
import transition from '../transition';
const IntroModal = ({ startQuiz }) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    startQuiz(); // Call the startQuiz function passed as a prop to initiate the quiz
  };
  const onHide = () =>{
    setShowModal(false)
    window.location.reload()
  }


 


  return (
<Modal show={showModal} onHide={onHide} className="custom-modal">
  <Modal.Header>
    <Modal.Title className='animate__bounceInLeft'>Welcome to the Quiz!</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className='text-center'>
      

    <p className='animate__animated animate__zoomIn'>
  Answer all 5 questions in a row to get on a heater.
</p>
<p className='animate__animated animate__zoomIn'>
  You will have 7 seconds to answer each question.
</p>
<p className='animate__animated animate__zoomIn'>
  If you exit the quiz prematurely, your score will be saved, and you will be unable to restart the daily quiz.
</p>


</div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="light" onClick={closeModal}>
      Start
    </Button>
  </Modal.Footer>
</Modal>
  );
};

export default IntroModal;
