export async function GetAllQuiz(email) {
    if (!email) {
      // Wait until the email is properly passed
      return null;
    }
  
    try {
      const response = await fetch('https://heaterbackend.darien-c.com/quiz/user-performances/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch user performances');
      }
  
      const allScores = await response.json();

  
      return {allScores};
    } catch (error) {
      console.error(error);
      // Handle error appropriately
      throw error;
    }
  }
  
