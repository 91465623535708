import React, { useState, useEffect, useCallback } from "react";
import './QuizPage.css'
import Modal from 'react-bootstrap/Modal';
import { Card } from 'react-bootstrap';
import transition from "../transition";
import { PostQuizScore } from '../services/PostQuizScore';
import IntroModal from './IntroModal';
import QuizTimer from './QuizTimer'
import ShowResults from './ShowResults';
import Countdown from 'react-countdown';
import CountDownModal from './CountDownModal'; // Import the CountDownModal component
import { AnonPostScore } from "../services/AnonPostScore";
const QuizPage = ({ quizData, quizID,email, loginStatus }) => {
    const [showQuizModal, setShowQuizModal] = useState(false);
    const [showResultsModal, setShowResultsModal] = useState(false)
    const [answersArray, setAnswersArray] = useState([]);
    const [activeCard, setActiveCard] = useState(null);
    const [questionSubmitted, setQuestionSubmitted] = useState('');
    const [questionNumber, setQuestionNumber] = useState(0)
    const [quizName, setQuizName] = useState('')
    const [userEmail, setUserEmail] = useState(null)
    const [isIntroModalOpen, setIsIntroModalOpen] = useState(true);
    const [questionsSubmitted, setQuestionsSubmitted] = useState([])
    const [showCountdown, setShowCountdown] = useState(true);
    const [resultsArray, setResultsArray] = useState('');
    const [score, setScore] = useState(null);
    const [timesOut, setTimesOut] = useState(false)
    const [nextQuestionCountdown, setNextQuestionCountdown] = useState(false)
    const [nextPrompt, setNextPrompt] = useState('')
    const [showCountdownModal, setShowCountdownModal] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false)
    const [animateClass, setAnimateClass] = useState('animate__animated animate__zoomIn'); // Initial animation class

    const closeModal = () => {
      setShowResultsModal(false);
      // Perform any additional actions upon closing the modal
    };
    const closeCountdownModalFunction = useCallback(() => {
      setShowQuizModal(true);
      setShowCountdownModal(false);
    }, [setShowQuizModal, setShowCountdownModal]);
    


  
    const handleHideOverlay = async () => {
      if(email){
        try {
          await PostQuizScore(email, quizID, questionNumber);
          window.location.reload();
        } catch (error) {
          console.error('Failed to post quiz score and reload the page:', error);
        }

      }else{
        try{
          await AnonPostScore(quizID,questionNumber);
          window.location.reload();
        
      }catch(error){
        console.error('Failed to post score')
      }}
      
    };
    const handleToggleCountdown = () => {
      setShowCountdown((prevShowCountdown) => !prevShowCountdown);
    };

  

  
    const sendAnswerCheckRequest = async (answerId) => {
      setActiveCard(answerId);
      setTimesOut(true)
      console.log(questionNumber)
      handleToggleCountdown()
      
      
      const url = 'https://heaterbackend.darien-c.com/quiz/check-answer/';
      const anonURL = 'http://127.0.0.1:8000/quiz/postAnonScore/'
      const formData = new FormData();
      formData.append('answer_id', answerId);
  
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();

        
            const cards = document.getElementsByClassName('custom-card')

            const cardElement = document.getElementById(answerId);

            const answerSubmitted = cardElement.querySelector('.card-title').textContent;

            // Update the questionsSubmitted array using useState
            setQuestionsSubmitted((prevQuestionsSubmitted) => [...prevQuestionsSubmitted, answerSubmitted]);

            for (let i = 0; i < cards.length; i++){
                const cardID = cards[i].id

                if(answerId == cardID){
                    cardElement.style.opacity = '1';


                }
                else{
                    const cardRemove = document.getElementById(cardID)
                    cardRemove.style.transition='opacity 0.5s'
                    cardRemove.style.opacity = '0';


                }


            }
            if (data['is_correct'] === true) {
              setTimeout(() => {

                cardElement.style.backgroundColor = '#98FB98';
            
                if(questionNumber<4){
                  setNextPrompt('Next Question In:')
                  setNextQuestionCountdown(true)
                }
                else{
                  setNextPrompt('Congratulations! You Are On A Heater!')
                }
              }, 1000);

                setTimeout(() => {
                  
                  if(questionNumber <4 ){
                    setQuestionNumber(questionNumber + 1)
                    setTimesOut(false)
                  }else{
          
                    
                     
                    if(loginStatus){
                      PostQuizScore(email, quizID, questionNumber + 1)
                       
                    }else{
                      AnonPostScore(quizID, questionNumber + 1)

                    }
                    
                    setScore(questionNumber + 1);
                   
                    setShowQuizModal(false);
                    setShowResultsModal(true);
                  }
                    
              
                  

                  
                }, 3900);


              } 
              else {
                setTimeout(()=>{
                  cardElement.style.backgroundColor = '#FFA07A';
                  setNextPrompt('Incorrect! See Results In:')
                  setNextQuestionCountdown(true)
                }, 1000)
                
                if(loggedIn){
                  PostQuizScore(email, quizID, questionNumber)
                  .then((responseData) => {
                    
  
  
                    // Set a delay of 1 second (1000 milliseconds) before updating state
                    setTimeout(() => {
                      if(!questionNumber){
                        setScore(19)
                      }else{
                        setScore(questionNumber);
                      }
                      
                     
                      setShowQuizModal(false);
                      setShowResultsModal(true);
                    }, 3900);
                  })
                  .catch((error) => {
                    console.error(error);
                    // Handle error
                  });

                }else{
                  AnonPostScore(quizID, questionNumber)
                  .then((responseData) => {
                    
  
  
                    // Set a delay of 1 second (1000 milliseconds) before updating state
                    setTimeout(() => {
                      if(!questionNumber){
                        setScore(19)
                      }else{
                        setScore(questionNumber);
                      }
                      
                     
                      setShowQuizModal(false);
                      setShowResultsModal(true);
                    }, 3900);
                  })
                  .catch((error) => {
                    console.error(error);
                    // Handle error
                  });
                }

              }

          

        return data;
        

        
      } catch (error) {
        console.log(error);
      }
    };
    const startQuiz = () => {
      setIsIntroModalOpen(false);
      setShowCountdownModal(true);    
    };
    const handleCountdownZero = () => {
      // Perform the desired action when the countdown reaches zero
      setTimesOut(true)
      setShowCountdown(false)
      
      

      const cardElements = document.getElementsByClassName('custom-card inactive card');
    
      for (let i = 0; i < cardElements.length; i++) {
        const cardElement = cardElements[i];
        cardElement.style.backgroundColor = '#FFA07A';
    
        const resultElement = document.createElement('div');
        resultElement.textContent = 'Time Ran Out';
        resultElement.className = 'result-text text-center animate__animated animate__zoomIn';
        cardElement.appendChild(resultElement);
      }
    
      PostQuizScore(email, quizID, questionNumber)
        .then((responseData) => {

    
          // Set a delay of 5 seconds (5000 milliseconds) before updating state
          setTimeout(() => {
            if(questionNumber === 0){
              setScore(19);

            }
            else{
              setScore(questionNumber)
            }
            
            setShowQuizModal(false);
            setShowResultsModal(true);
          }, 5000);
        });
    };
    const handleNextQuestion = () =>{
      setNextQuestionCountdown(false)
    }
    
  
    

    useEffect(()=>{
      

      setAnswersArray(quizData[quizID - 1]['questions'][questionNumber]['answers'])
      setQuizName(quizData[quizID - 1]['title'])
      setResultsArray(quizData[quizID - 1])
      

    })
    useEffect(()=>{
      setUserEmail(email)
    }, [email])
    useEffect(()=>{
      setLoggedIn(loginStatus)
    })

    
    useEffect(() => {
      handleToggleCountdown()
    }, [answersArray]);

    useEffect(()=>{
      if(showCountdown){
        handleNextQuestion()
      }
    },[showCountdown])
    useEffect(()=>{
      console.log(showQuizModal)
    }, [showQuizModal])
    useEffect(()=>{
      console.log('showCountdownModal is ', showCountdownModal)
    },[showCountdownModal])
    







    return (
      <div>
         <IntroModal startQuiz={startQuiz} />
        {showCountdownModal &&       <CountDownModal closeCountdownModal={closeCountdownModalFunction} show={showCountdownModal}/>
}
 
        <Modal show={showQuizModal} onHide={handleHideOverlay}>
          <Modal.Header>
          <Modal.Title className='text-center animate__animated animate__zoomIn' key={questionNumber}>
          {quizData[quizID - 1]['questions'][questionNumber]['text']}
            <div className="text-center">
            <h6>{(questionNumber + 1)}/5</h6>
            {showCountdown && (
            <Countdown
              date={Date.now() + 7000} // Set the countdown duration in milliseconds (7 seconds)
              onComplete={handleCountdownZero} // Function to execute when the countdown reaches zero
              renderer={({ seconds }) => <div className="animate__animated animate__zoomIn">{seconds}</div>} // Renderer function to display the countdown value
            />
          )} 
          {nextQuestionCountdown && (
             <Countdown
             date={Date.now() + 3000} // Set the countdown duration in milliseconds (7 seconds)
             onComplete={handleNextQuestion} // Function to execute when the countdown reaches zero
             renderer={({ seconds }) => <div className="animate__animated animate__bounceIn">{nextPrompt} {seconds}</div>} // Renderer function to display the countdown value
           />


          )}
          </div>

            
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {quizData &&
              answersArray.map((answer) => (
                <div key={answer.id}>
                  
                  <Card 
                  className={`custom-card ${activeCard === answer.id ? 'active' : 'inactive'}`}
                  id={answer.id}

                  onClick={() => {
                    if (!timesOut) {
                      sendAnswerCheckRequest(answer.id);
                    }
                   
                  }}
                  >
                    <Card.Body>
                      <Card.Title className="card-title text-center animate__animated animate__zoomIn">{answer.text}</Card.Title>

                    </Card.Body>
                  </Card>
                  <br />
                </div>
              ))}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        
        {showResultsModal && <ShowResults resultsArray={resultsArray} show={showResultsModal} onHide={closeModal} scoreSet={score} reloadHide={true}/>}

      </div>
    );
  };
  
  export default QuizPage;

