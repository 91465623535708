export async function GetScores(email) {
    const apiUrl = 
    'https://heaterbackend.darien-c.com/quiz/checkScore/'

    const data = {
        email: email,
      };
    
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
      if (response.ok) {
        const scores = await response.json();
        return {scores}
        // Handle successful response
      } else {
        throw new Error('Error occurred while fetching the scores.');
      }
    } catch (error) {
      console.error(error);
      // Handle error
    }
  }
  
