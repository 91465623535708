import React, { useState, useEffect } from 'react';
import HomeModal from '../components/HomeModal';
import { QuizFetch } from '../services/QuizFetch';
import { GetAllQuiz } from '../services/GetAllQuiz';
import { UserData } from '../services/UserData';
import loginLogo from './loginIcon.png';
import LoginModal from '../components/LoginModal';
import DashBoard from '../components/DashBoard';
import { GetScores } from '../services/GetScores';
import { CheckTime } from '../services/CheckTime';
import OpeningModal from '../components/OpeningModal';
import './Home.css';
import transition from '../transition'
import logoPic from './home-logo.png';
import { AnonGetScore } from '../services/AnonGetScore';
const Home = () => {
  const [quizDataFinal, setQuizDataFinal] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [quizScores, setQuizScores] = useState(null);
  const[allScores, setAllScores] = useState(null);
  const [showHomeModal, setShowHomeModal] = useState(false);
  const [showOpeningModal, setShowOpeningModal] = useState(false)


  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      UserData(authToken)
        .then((data) => {
          setUserName(data['username']); // Access the username
          setUserEmail(data['email']); // Access the email
        })
        .catch((error) => {
          console.error(error);
        });
        
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      if (userEmail) {
        GetScores(userEmail)
          .then((response) => {
            setQuizScores(response.scores);
            console.log(quizScores);
          })
          .catch((error) => {
            console.error(error);
          });
        GetAllQuiz(userEmail)
          .then((response) => {
            setAllScores(response.allScores);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      AnonGetScore()
        .then((response) => {
          console.log("scores by quiz", response);
          setQuizScores(response);

        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [userEmail]);
  

  useEffect(() => {
    QuizFetch()
      .then((data) => {
        setQuizDataFinal(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    const checkAndShowModal = async () => {
      if(localStorage.getItem('visitCheck')){
        
      }
      else{
        setShowOpeningModal(true)
        localStorage.setItem('visitCheck', true)
      }
    };
    checkAndShowModal()

    
  }, []);

  const closeHomeModal = () => {
    setShowHomeModal(false);
  };



  return (
    <div className="home_container">
      
      <div className="dashboard_container">
        <img src={logoPic} className='homeLogo'/>
        <a href="#" onClick={() => setShowLoginModal(true)}>
          <img src={loginLogo} alt="loginLogo" className="logo" />
        </a>

        <div>
          {showOpeningModal && (
                <OpeningModal/>
          )}
        {showHomeModal && (
        <HomeModal
          quizDataFinal={quizDataFinal}
          email={userEmail}
          userQuizScores={quizScores}
          onClose={closeHomeModal}
        />
      )}          <DashBoard email={userEmail} userQuizScores={quizScores} allQuizScores={allScores}/>
          <LoginModal show={showLoginModal} close={() => setShowLoginModal(false)} userName={userName} email={userEmail} />
        </div>
      </div>
    </div>
  );
};

export default transition(Home);
