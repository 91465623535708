import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './CountDownModal.css'
const CountDownModal = ({ closeCountdownModal, show}) => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        clearInterval(countdownInterval);
        closeCountdownModal(); // Call the function to close the modal after countdown
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval); // Clean up the interval on unmount
    };
  }, [count, closeCountdownModal]);

  return (
    <div>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title className="animate__animated animate__zoomIn">Quiz Will Begin In:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            <div className="animate__animated animate__zoomIn">
            <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>{count}</h1>
          </div>
          </div>
        </Modal.Body>
        {/* No footer needed for countdown */}
      </Modal>
    </div>
  );
}

export default CountDownModal;
