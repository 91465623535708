export async function PostQuizScore(email, quiz_id, score) {

    const apiUrl = 'https://heaterbackend.darien-c.com/quiz/post_quiz_score/';
  
    const data = {
      email: email,
      quiz_id: quiz_id,
      score: score
    };
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        const responseData = await response.json();

        return responseData;
        
        // Handle successful response
      } else {
        throw new Error('Error occurred while submitting the score.');
      }
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };