export async function GoogleLoginFunc(response) {
    const idToken = response.credential;
    const url = 'https://heaterbackend.darien-c.com/quiz/googleLogin/'

    try{
      const backendResponse = await fetch(url,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json'
          
        },
        credentials: "include",
        body: JSON.stringify({idToken})
      });
      const data = await backendResponse.json();
      let token = data['token']
      if (backendResponse.ok) {
        // login successful
        // store the token in local storage or session storage

        localStorage.setItem("authToken", token)
        window.location.reload();



        
  

        // redirect to dashboard or profile page
        
      } else {
        // login failed
        console.log('error')
      }
    
    } catch (error) {
      console.error('Error:', error);
    }}
  

