import React from 'react'
import { useEffect, useState } from 'react'
import './DashBoard.css'
import { QuizFetch } from '../services/QuizFetch'
import QuizPage from './QuizPage'
import {GetAllQuiz} from '../services/GetAllQuiz' 
import { GetScores } from '../services/GetScores'
import ShowResults from './ShowResults'
import LoginModal from './LoginModal';
import Box from '@mui/material/Box';
import { PostQuizScore } from '../services/PostQuizScore'
import { Ring } from '@uiball/loaders'
import startbutton from './StartButton.png';
import CountDownModal from './CountDownModal';

const DashBoard = ({ email, userQuizScores, allQuizScores }) => {
  const [quizDataFinal, setQuizDataFinal] = useState(null)
  const [quizTitleArray, setQuizTitleArray] = useState([])
  const [quizScoreArray, setQuizScoreArray] = useState(null)
  const [allUserQuizScores, setAllUserQuizScores] = useState(null)
  const [showQuiz, setShowQuiz] = useState(false)
  const [quizID, setQuizID] = useState(null)
  const[loggedIn, setLoggedIn] = useState(false)
  const [userEmail, setUserEmail] = useState(null)
  const [scoreVariable, setScoreVariable] = useState(<p>Loading...</p>)
  const [showLogin, setShowLogin] = useState(false)
  const [showStart, setShowStart] = useState(false)
  const [ resultsArray, setResultsArray] = useState(null);
  const [ show, setShow] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const[dailyQuiz, setDailyQuiz] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [anonScores, setAnonScores] = useState('')
  const [loggedInScore, setLoggedInScore] = useState('')

  

  const dailyArray = []
  let dailyScores = {}
  let politicScore = {}
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    // Perform any additional actions upon closing the modal
  };
  const openQuiz = (quizID) => {
    setQuizID(quizID);
    setDailyQuiz(dailyArray[quizID] - 1);
    console.log('dailyScore is:', dailyScores)
    
  
    let found = false;
    for (let i = 0; i < dailyArray.length; i++) {
      console.log(dailyArray[i])
      if (dailyArray[i] === quizID) {
        setResultsArray(quizDataFinal[quizID - 1]);
        setShowModal(true);
        found = true;
        break; // Exit the loop since the quizID is found
      }
    }
  
    if (!found) {
      setShowQuiz(true);
    }
  };
  const handleLoginShow = () => {
    setShowLogin(true);
    
  };

    

    

    
  
  useEffect(() => {
    
    
    async function fetchData() {
      try {
        const data = await QuizFetch();
        if (data) {
          setShowStart(true);
          setQuizDataFinal(data);
          console.log("quizData", data)
          for (let i = 0; i < data.length; i++) {
            quizTitleArray.push(data[i]);
          }
        } else {
          // Use setTimeout with a function reference
          setTimeout(() => {
            fetchData();
          }, 2500);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error as needed
      }
    }
    const checkLogin =()=>{
      const authToken = localStorage.getItem('authToken')
      if(authToken){
        setLoggedIn(true)
      }
      else{
        setLoggedIn(false)
        
      }

    }
    
    checkLogin();

    fetchData();

    
  }, []);
  useEffect(() => {
    setUserEmail(email);
    setQuizScoreArray(userQuizScores);
    setAllUserQuizScores(allQuizScores)
    setIsLoading(false)
  }, [email, userQuizScores]);
  useEffect(() => {
    const CheckAnonScore = () => {
      if (
        email &&
        localStorage.getItem('authToken') &&
        localStorage.getItem('quizID') &&
        localStorage.getItem('anonScore')
      ) {
        const quizID = localStorage.getItem('quizID');
        let anonScore = localStorage.getItem('anonScore');
  
        // Use try-catch to handle potential errors in PostQuizScore
        try {
          if(anonScore == 19){
            anonScore=0
          }
          PostQuizScore(email, quizID, anonScore);
          localStorage.removeItem('quizID');
          localStorage.removeItem('anonScore');
          window.location.reload()
        } catch (error) {
          console.error('Error occurred while posting quiz score:', error);
        }
      }
    };
  
    // Run CheckAnonScore only when the email changes and is not null
    if (email !== null) {
      CheckAnonScore();
    }
  }, [email]);

  const CheckAnonScores = (quizTitle,index) => {
    let score =''
    if (!quizScoreArray) {
      return <p>Loading...</p>;
    }
  
    if (quizScoreArray.length === 0) {
      return (
        <div className='startButton'>
          <img src={startbutton} alt='startbutton' className='startButtonImg'/>
        </div>
      );
    }
    else{

    try {

      
      score = quizScoreArray['scores_by_quiz'][quizTitle['id']][0];
      const formattedScore = `${score.scores} / 5`;
      console.log(score.scores)
      if(score){

        dailyArray.push(score['quiz_id'])
  
        dailyScores[quizTitle['id']]= score.scores
        console.log("dailyScores",dailyScores)

  
      }
  
      return (
        <div>
          <div><h6>Today's Score</h6></div>
  
          <div className="score-container">
            <p className="score">{formattedScore}</p>
            <div className="score-bar">
              <div className="score-fill" style={{ width: `${(score.scores / 5) * 100}%` }}></div>
            </div>
          </div>
        </div>
      );
    } catch {
      console.log('fail')
      return (
        <div className='startButton'>
          <img src={startbutton} alt='startbutton' className='startButtonImg'/>
        </div>
      );
    }
  }};

  const CheckScores = (quizTitle,index) => {
    console.log(quizScoreArray)
    let score = ''

    if (!quizScoreArray) {
      return <p>Loading...</p>;
    }
  
    if (quizScoreArray.length === 0) {
     console.log("QuizScoreArray ," + quizScoreArray)
      return (
        <div className='startButton'>
        <img src={startbutton} alt='startbutton' className='startButtonImg'/>
      </div>
      )
      
    }

    score = quizScoreArray.find((item) => item.quizType === (index + 1));
    
    
    
    if(score){

      dailyArray.push(score['quizType'])
      console.log(dailyArray)


      dailyScores[score['quizType']] = score['score']
      console.log(dailyScores)

    }
    if (score?.quizType === 4) {
      let quizNum = score['quizType']
      politicScore['quizType'] = quizNum
      politicScore['score'] = score['score']
      
  }

    if (score) {

      const formattedScore = `${score.score} / 5`;
     
      return (
        <div>
          <div><h6>Today's Score</h6></div>
        
        <div className="score-container">
          
          <p className="score">{formattedScore}</p>
          <div className="score-bar">
            <div className="score-fill" style={{ width: `${(score.score / 5) * 100}%` }}></div>

          </div>
          </div>
        </div>
      );
    } else {
      return(
        <div className='startButton'>
        <img src={startbutton} alt='startbutton' className='startButtonImg'/>
      </div>
      )
    }
  };




  
  const CheckAllScores = (index) => {
    
    if (!allQuizScores) {
      return <p>Loading...</p>;
    }
  
    if (allQuizScores.length === 0) {
      return <p>No Quiz History</p>;
    }
  
    const quizHistory = allQuizScores.filter((item) => item.quizType === (index + 1));
    const totalQuestions = quizHistory.length * 5; // Assuming each quiz consists of 5 questions
  
    // Calculate the total score
    const totalScore = quizHistory.reduce((acc, item) => acc + item.score, 0);
  
    // Calculate the percentage score
    const percentageScore = (totalScore / totalQuestions) * 100;
  
    const colorScale = (score) => {
      const red = Math.round((5 - score) * 51); // Calculate red component (0 to 255)
      const green = Math.round(score * 51); // Calculate green component (0 to 255)
      return `rgb(${red}, ${green}, 0)`; // Construct color value
    };
  
    if (quizHistory.length > 0) {
      return (
        <div>
          <div className="percentage-score">History</div>
          <div className="box-container">
            {quizHistory.map((item, i) => (
              <Box
                key={i}
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: colorScale(item.score),
                  padding: '5px',
                }}
              ></Box>
            ))}
          </div>

        </div>
      );
    } else {
      return <p>No Recent History</p>;
    }
  };

  
  if (!showStart) {
    // Show the loading page while the data is being fetched
    return (
    <div className="centered-div">
    <Ring size={100} speed={1.4} color="black" />
  </div>
);
  }
  else{
  return (
    <div>

<div className="dashboard-container">
  <br/>
  <div className="quiz-list">
    {quizTitleArray.map((quizTitle, index) => {
      let score = ''
      if(loggedIn){
        score = CheckScores(quizTitle,index); // Call the CheckScores function and store the result
      }
      else{
        score = CheckAnonScores(quizTitle, index)
      }
      

      return (

        <div key={index} className="quiz-item" onClick={() => openQuiz(index + 1) }>
          <h3 className="quiz-title">{quizTitle['title']}</h3>
          {loggedIn ? (
            <div>
              <div className="quiz-details">
                {score}
              </div>
              
            </div>
          ) : (
            <div className="quiz-details">
              {score}
      
              
            </div>
          )}
        </div>
      );
    })}
  </div>
</div>
    {showQuiz && <QuizPage quizData={quizDataFinal} quizID={quizID} email={userEmail} loginStatus={loggedIn}/>}
    {showModal && <ShowResults resultsArray={resultsArray} show={showModal} onHide={closeModal} score={dailyScores} pScore={politicScore}/>}
    <LoginModal show={showLogin} close={() => setShowLogin(false)} />



    </div>
  )
}}

export default DashBoard
